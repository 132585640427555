import i18n from '@/i18n'

export enum Advantages {
  pool = 'pool-of-freelancers',
  designerPortfolios = 'designer-portfolios',
  safeAndSecure = 'safe-and-secure',
  milestonePayments = 'milestone-payments',
  realTimeTracking = 'real-time-tracking',
  interactiveFeedback = 'interactive-feedback-tool',
  seamlessCommunications = 'seamless-communications',
  multideviceSupport = 'multi-device-support',
  fullCopyrightOwnership = 'full-copyright-ownership',
  // cloudStorage = 'could-storage'
}

export const AdvantagesData: any = {
  [Advantages.pool]: {
    title: i18n.t('Immense pool of freelancers'),
    description: i18n.t('Thousands of finest talent in one place, and clever tools to find the right one for you'),
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.pool}.svg`),
  },
  [Advantages.designerPortfolios]: {
    title: i18n.t('Designer portfolios'),
    description: i18n.t(
      'Find professionals you can trust by evaluating their previous work examples and checking their performance statistics'
    ),
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.designerPortfolios}.svg`),
  },
  [Advantages.safeAndSecure]: {
    title: i18n.t('Safe and secure'),
    description: i18n.t('Rest assure, we go an extra mile to ensure extreme security when it comes to payments'),
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.safeAndSecure}.svg`),
  },
  [Advantages.milestonePayments]: {
    title: i18n.t('Milestone payments'),
    description: i18n.t(
      'Some projects can get big and complex, thats why it’s very convenient to issue payments as you advance with your project and  when you are completely satisfied.'
    ),
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.milestonePayments}.svg`),
  },
  [Advantages.realTimeTracking]: {
    title: i18n.t('Real time tracking'),
    description: i18n.t(
      'You can track your project in real time as each milestone progresses and always can see whats done and what is still ahead.'
    ),
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.realTimeTracking}.svg`),
  },
  [Advantages.interactiveFeedback]: {
    title: i18n.t('Interactive feedback tool'),
    description: i18n.t(
      'Leaving feedback on graphic designs, requires graphic tools, so we integrated easy to use graphic editor, to mark and explain your ideas.'
    ),
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.interactiveFeedback}.svg`),
  },
  [Advantages.seamlessCommunications]: {
    title: i18n.t('Seamless communications'),
    description: i18n.t(
      'When it comes to discussing design projects at distance, effective communication is critical, thats why we have chats, audio or video calls and live screensharing with End-to-End encryption.'
    ),
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.seamlessCommunications}.svg`),
    coming: true,
  },
  [Advantages.multideviceSupport]: {
    title: i18n.t('Multi-device support'),
    description: i18n.t(
      'Mobile, tablet or desktop, Get.Design simply works on any device, so you can access your project on any device at any time, through any modern web browser.'
    ),
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.multideviceSupport}.svg`),
  },
  [Advantages.fullCopyrightOwnership]: {
    title: i18n.t('Full copyright ownership'),
    description: i18n.t(
      'Professionally drafted by our Legal advisors and signed by Advanced Electronic Signatures (AdES), so your contracts are 100% legaly enforceable.'
    ),
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.fullCopyrightOwnership}.svg`),
    coming: true,
  },
  /* [Advantages.cloudStorage]: {
    title: 'Cloud storage',
    description: 'you can use Get.Design cloud to easily share and reach you designs anytime.',
    icon: () => import(`@/assets/images/advantages/inline.${Advantages.cloudStorage}.svg`)
  }, */
}
